<template>
  <form class="form" @submit.prevent="onSubmit">
    <template v-if="type === 'pinCode'">
      <!-- PIN -->
      <div class="form__text">
        <img class="form__text__img" :src="require('@/assets/img/keyboard-gray.svg')" />
        <input class="form__text__input" type="number" v-model="pin" placeholder="Mã PIN" />
      </div>
    </template>
    <template v-else>
      <!-- 名前 -->
      <label class="form__label">Tên<span>*</span></label>
      <input class="form__input" type="text" v-model="profile.lastname" placeholder="Họ" />
      <input class="form__input--second" type="text" v-model="profile.firstname" placeholder="Tên" />
      <!-- 生年月日 -->
      <label class="form__label">Ngày tháng năm sinh<span>*</span></label>
      <div class="form__select">
        <p :class="age.length != 0 ? 'form__select__area' : 'form__select__area--none'"
        @click="$refs.datepicker.open()">{{ age.length === 0 ? 'Chọn' : age }}</p>
        <img class="form__select__arrow" src="@/assets/img/left-arrow-gray.svg" />
      </div>
      <!-- 性別 -->
      <label class="form__label">Giới tính<span>*</span></label>
      <div class="form__select">
        <select :class="profile.sex.length != 0 ? 'form__select__area' : 'form__select__area--none'" v-model="profile.sex">
          <option disabled value="" style='display:none;'>Chọn</option>
          <option value="Nam">Nam</option>
          <option value="Nữ">Nữ</option>
        </select>
        <img class="form__select__arrow" src="@/assets/img/left-arrow-gray.svg" />
      </div>
      <!-- 日本語レベル -->
      <label class="form__label">Trình độ tiếng Nhật<span>*</span></label>
      <div class="form__select">
        <select :class="profile.level.length != 0 ? 'form__select__area' : 'form__select__area--none'" v-model="profile.level">
          <option disabled value="" style='display:none;'>Chọn</option>
          <option value="N5">N5</option>
          <option value="N4">N4</option>
          <option value="N3">N3</option>
          <option value="N2">N2</option>
        </select>
        <img class="form__select__arrow" src="@/assets/img/left-arrow-gray.svg" />
      </div>
      <!-- 職業 -->
      <label class="form__label">Nghề nghiệp</label>
      <div class="form__select">
        <select :class="profile.occupation.length != 0 ? 'form__select__area' : 'form__select__area--none'" v-model="profile.occupation">
          <option disabled value="" style='display:none;'>Chọn</option>
          <option v-for="id in Object.keys(occupations)" :key=id :value=id>{{ occupations[id].name }}</option>
        </select>
        <img class="form__select__arrow" src="@/assets/img/left-arrow-gray.svg" />
      </div>
      <!-- トピック -->
      <label class="form__label">Sở thích</label>
      <div class="form__select">
        <select :class="profile.topic.length != 0 ? 'form__select__area' : 'form__select__area--none'" v-model="profile.topic">
          <option disabled value="" style='display:none;'>Chọn</option>
          <option v-for="id in Object.keys(topics)" :key=id :value=id>{{ topics[id].name }}</option>
        </select>
        <img class="form__select__arrow" src="@/assets/img/left-arrow-gray.svg" />
      </div>
      <!-- 利用規約 -->
      <label class="form__label">
        <input type="checkbox" :value="true" class="form__checkbox" v-model="readedTerms">
        <span class="form__checkbox-letter">
          Tôi đã xác nhận các
          <a class="form__checkbox-letter__link" href="https://docs.google.com/document/d/1dRH6nhXH23toZ-izrE2v_d7IefMY8KPepMxFYgiUf-g/edit?usp=sharing" target="_blank">
            ĐIỀU KHOẢN VÀ QUY ĐỊNH
          </a>.
        </span>
      </label>
    </template>
    <v-btn class="form__btn" depressed rounded
    :ripple="false" :disabled="!canNext" type="submit">
      Tiếp
    </v-btn>
    <processing v-if="isProcessing"></processing>
    <!-- 日付選択 -->
    <datepicker ref="datepicker" :max="formated(new Date(), 'YYYY-MM-DD')" @setDate="setDate" />
  </form>
</template>

<script>
import CommonFunction from '@/lib/common'

import Processing from '@/components/common/processing'
import Datepicker from '@/components/common/datepicker'

export default {
  extends: CommonFunction,
  components: { Processing, Datepicker },
  props: {
    // ナビゲーションの活性（pinCode or avatar）
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      // ピンコード
      pin: '',
      // 入力されたPINコードから取得したPIN情報
      pinInfo: null,
      // PINの履歴情報
      history: null,
      // プロフィール時のパラメータ
      profile: {
        lastname: '',
        firstname: '',
        age: '',
        sex: '',
        level: '',
        occupation: '',
        topic: ''
      },
      // 表示用の年齢
      age: '',
      // 利用規約を読んだかどうか
      readedTerms: false,
      // 処理中かどうか
      isProcessing: false
    }
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['user/uid']
    },
    /**
     * @return {Object} 職業情報 { id: 職業のオブジェクト, ... }
     */
    occupations () {
      return this.$store.getters['occupation/occupations']
    },
    /**
     * @return {Object} トピック情報 { id: トピックのオブジェクト, ... }
     */
    topics () {
      return this.$store.getters['topic/topics']
    },
    /**
     * @return {Boolean} PINコードが整数8桁であるかどうか
     */
    collectPIN () {
      const integer = /^[0-9]*$/
      return integer.test(this.pin) && this.pin.length === 8
    },
    /**
     * @return {Boolean} 次に遷移できるか
     */
    canNext () {
      if (this.type === 'pinCode') {
        return this.collectPIN
      } else if (this.type === 'avatar') {
        // 非必須項目なもの
        const regexpOption = /occupation|topic/

        // 必須項目記入および利用規約同意の有無のチェック
        return Object.values(this.profile)
          .filter((value, index) => {
          // 非必須項目
            if (Object.keys(this.profile)[index].match(regexpOption)) {
              return true
            } else {
            // 必須項目
            // 日付か文字列かつ0以上の場合はOK
              return value instanceof Date ? true : value.length > 0
            }
          }).length === Object.values(this.profile).length &&
          this.readedTerms
      }
      return false
    }
  },
  methods: {
    /**
     * 日付をセット
     * @param {String} date 日付
     */
    setDate (date) {
      this.profile.age = date ? new Date(date) : ''
      this.age = date ? this.formated(this.profile.age, 'YYYY/MM/DD') : ''
    },
    /**
     * フォームの登録
     */
    async onSubmit () {
      if (this.type === 'pinCode') {
        // ピン入力画面であればプロフィール入力画面を表示
        this.$emit('changeNav', 'avatar')
      } else if (this.type === 'avatar') {
        // プロフィール入力画面であればPIN認証と登録を行う
        this.isProcessing = true
        const pin = await this.$store.dispatch('pin/getPIN', this.pin)

        if (Object.keys(pin).length === 0 || pin.uid !== null) {
          // pinが存在しない or 使用中の場合
          this.isProcessing = false
          this.$emit('changeNav', 'pinCode')
          this.$store.commit('setTelop', { show: true, msg: 'Mã PIN không chính xác.', type: 'error' })
        } else if (!pin.isAvailable) {
          // pinが利用停止中
          this.$router.push({ name: 'Error' })
        } else {
          // PINが利用可能なのでユーザー登録
          const pinHistorySnapshot = await this.$store.dispatch('pinHistory/getPINHistory', this.pin)

          if (pinHistorySnapshot.size !== 1) {
            this.$router.push({ name: 'Error' })
          } else {
            // アカウント登録処理
            let history = {}
            pinHistorySnapshot.forEach(doc => { history = doc.data() })
            const startAt = new Date()
            const endAt = new Date()
            endAt.setDate(endAt.getDate() + history.plan)

            // デバイスID取得
            // フィンガープリント取得時の例外パラメータ
            const options = require('@/fingerPrintOption.json')
            const deviceID = await this.getDeviceID(options)

            // ユーザー情報
            const userInfo = {
              uid: this.uid,
              param: Object.assign(this.profile, { pin: this.pin, authority: 'user', updatedAt: startAt, createdAt: startAt, deviceID: deviceID })
            }

            // 更新するPIN情報
            const pinInfo = {
              pin: this.pin,
              uid: this.uid,
              startAt: startAt,
              endAt: endAt,
              updatedAt: startAt
            }

            // 作成するPIN履歴情報
            const pinHistoryInfo = {
              uid: this.uid,
              pin: this.pin,
              customer: history.customer,
              status: '利用開始',
              plan: history.plan,
              startAt: startAt,
              endAt: endAt
            }

            // 登録と初期処理の実施
            await Promise.all([
              this.$store.dispatch('user/createUser', userInfo),
              this.$store.dispatch('pin/registratePIN', pinInfo),
              this.$store.dispatch('pinHistory/createPINHistory', pinHistoryInfo),
              this.$store.dispatch('auth/initialize', this.uid)
            ])

            this.$store.commit('setTelop', { show: true, msg: 'Thủ tục đăng ký đã được hoàn tất.', type: 'success' })
            this.$router.push({ name: 'Home' })
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';
@import '@/assets/sass/color.scss';

.form {
  width: 100%;
  &__text {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 5px;
    color: $white_color;
    font-size: 16px;
    border-bottom: 1px solid $gray_color;
    &__input {
      display: inline-block;
      vertical-align: middle;
      padding-left: 5px;
      width: calc(100% - 16px - 5px);
      margin: 0;
      &::placeholder {
        color: $gray_darken_color;
        opacity: 0.4;
      }
    }
    &__img {
      height: 20px;
      width: 20px;
      object-fit: contain;
    }
  }
  &__label {
    display: block;
    margin-top: 30px;
    margin-bottom: 12.5px;
    color: $white_color;
    font-size: 12px;
    font-weight: bold;
    span {
      color: $red_color;
    }
  }
  &__input {
    width: 100%;
    padding: 0 5px;
    color: $white_color;
    font-size: 16px;
    border-bottom: 1px solid $gray_color;
    &--second {
      @extend .form__input;
      margin-top: 20px;
    }
    &::placeholder {
      color: $gray_darken_color;
      opacity: 0.4;
    }
  }
  &__select {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 5px;
    color: $white_color;
    font-size: 16px;
    border-bottom: 1px solid $gray_color;
    &__area {
      flex: 1;
      margin: 0;
      min-width: 0;
      &--none {
        @extend .form__select__area;
        color: $gray_darken_color;
        opacity: 0.4;
      }
    }
    &__arrow {
      width: 18.6px;
      height: 18.6px;
      object-fit: contain;
      transform: rotate(90deg);
    }
  }
  &__checkbox {
    display: none;
  }
  &__checkbox-letter {
    position: relative;
    display: inline-block;
    padding-left: 23px;
    color: $white_color !important;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 17px;
      height: 17px;
      border: solid 1px $white_color;
    }
    &__link {
      color: $orange_lighten_color;
    }
  }
  &__btn {
    display: block;
    width: 113px;
    margin-top: 47px;
    margin-left: auto;
    font-size: 17px;
    color: $white_color;
    text-transform: none;
    &.v-btn:not(.v-btn--round) {
      height: 44px;
    }
    &.theme--light.v-btn:not(.v-btn--flat) {
      background-color: $orange_lighten_color;
    }
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat) {
      color: $white_color !important;
      background-color: $gray_darken_color !important;
    }
  }
}

.form__checkbox:checked + .form__checkbox-letter::after{
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 5px;
  width: 6.2px;
  height: 8.9px;
  transform: rotate(40deg);
  border-bottom: 2px solid $white_color;
  border-right: 2px solid $white_color;
}
</style>
