<template>
  <div class="signup">
    <header class="signup__header">
      <button class="signup__header__back" @click="backNav()">
        <img src="@/assets/img/arrow_foward-white.svg" />
      </button>
      <h1 class="signup__header__title">Đăng ký</h1>
    </header>
    <signup-nav class="signup__nav" :type="type"></signup-nav>
    <signup-form class="signup__form" :type="type" @changeNav="changeNav"></signup-form>
    <popup v-if="openPopup"
    title='Bạn có chắn rằng muốn rời khỏi trang này không?'
    message='Nếu bạn rời khỏi trang, những thông tin đăng ký sẽ bị xóa.'
    @close="closePopup" @back="back"></popup>
  </div>
</template>

<script>
import Popup from '@/components/common/backPopup'
import SignupNav from '@/components/signup/nav'
import SignupForm from '@/components/signup/form'

export default {
  components: { Popup, SignupNav, SignupForm },
  data () {
    return {
      // 警告ポップアップがオープン中か
      openPopup: false,
      // ナビゲーションの活性（pinCode or avatar）
      type: 'pinCode'
    }
  },
  async mounted () {
    // ユーザーが存在する場合はホーム画面へ遷移する
    if (this.user) this.$router.push({ name: 'Home' })

    // 職業とトピック情報の取得
    await Promise.all([this.$store.dispatch('occupation/getOccupations'), this.$store.dispatch('topic/getTopics')])
  },
  computed: {
    /**
     * @return {Object} ユーザー情報
     */
    user () {
      return this.$store.getters['user/user']
    }
  },
  methods: {
    /**
     * 戻る
     */
    back () {
      this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'Login' })
    },
    /**
     * 戻る
     */
    backNav () {
      if (this.type === 'pinCode') {
        this.openPopup = true
      } else {
        this.type = 'pinCode'
      }
    },
    /**
     * ナビゲーションを切り替える
     * @param {String} type ナビゲーションタイプ
     */
    changeNav (type) {
      this.type = type
    },
    /**
     * 遷移確認ポップアップを閉じる
     */
    closePopup () {
      this.openPopup = false
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';
@import '@/assets/sass/color.scss';

.signup {
  min-height: 100vh;
  width: 100vw;
  padding: 0 37px;
  padding-bottom: 100px;
  text-align: left;
  background-color: $blue_color;
  &__header {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: $header_height;
    width: 100vw;
    text-align: center;
    background-color: $blue_darken_color;
    z-index: 100;
    &__back {
      position: absolute;
      top: 0;
      left: 26px;
      bottom: 0;
      height: 20px;
      width: 20px;
      margin: auto;
    }
    &__title {
      color: $white_color;
      font-size: 20px;
      font-weight: normal;
    }
  }
  &__nav {
    margin-top: 153px;
  }
  &__title {
    margin: 0;
    margin-top: 75px;
    font-size: 20px;
    font-weight: bold;
    color: $white_color;
  }
  &__form {
    margin-top: 49px;
  }
}
</style>
