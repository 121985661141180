<template>
  <div class="nav">
    <div class="nav__area">
      <!-- PINコード入力の場合 -->
      <div class="nav__area__left" v-if="type === 'pinCode'">
        <h2 class="nav__area__left__title">Nhập<br>mã PIN</h2>
        <p class="nav__area__left__text">Xin hãy nhập mã PIN mà bạn nhận được.</p>
      </div>
      <!-- プロフィール入力の場合 -->
      <div class="nav__area__left" v-else-if="type === 'avatar'">
        <h2 class="nav__area__left__title">Thông tin<br>về bạn</h2>
        <p class="nav__area__left__text">Vui lòng điền đầy đủ thông tin của bạn dưới đây.</p>
      </div>
      <!-- 進行度 -->
      <p class="nav__area__progress">
        <span class="nav__area__progress__large">{{ type === 'pinCode' ? '1' :'2' }}</span>/2
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // ナビゲーションの活性（pinCode or avatar）
    type: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';
@import '@/assets/sass/color.scss';

.nav {
  position: relative;
  width: 100%;
  color: $white_color;
  text-align: left;
  &__area {
    display: flex;
    align-items: flex-end;
    &__left {
      flex: 1;
      margin-right: 10px;
      &__title {
        font-size: 35px;
      }
      &__text {
        margin: 0;
        font-size: 12px;
      }
    }
    &__progress {
      margin: 0;
      font-size: 20px;
      font-weight: bold;
      line-height: 1.35;
      letter-spacing: 8px;
      &__large {
        font-size: 30px;
      }
    }
  }
}
</style>
